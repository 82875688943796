import * as React from "react"
import { Link } from "gatsby"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import "../styles/404.scss"


// markup
const NotFoundPage = () => {
  return (
    <Layout SEOTitle={"Page not found - 404"}>
      <div className="noPageFound">
        <div className="uk-text-center errorDetails">
          <h1>404</h1>
          <h3><b>Sorry, we can't find that page</b></h3>
          {/* <h4>Please try one of the following</h4> */}
          <div className="ctas" >
            <Link to="/" className="returnHomeBtn">Return home</Link>
            {/* <button onClick={() => {console.log("test")}}>Click on page</button> */}
            {/* <Link to="/products" className="getAQuote">Get a quote</Link> */}
           
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
