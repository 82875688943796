import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import "../styles/global.scss"
import Seo from "../components/seo/seo.js"
import Footer from "../components/footer/footer"
import Nav from "../components/nav/nav"

import 'uikit/dist/css/uikit.css'
import UIkit from 'uikit'

const Layout = ({ children, pageData, SEOTitle, SEODescription, footerDisclaimer, darkThemeNav }) => {

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const indexLastClosedTime = sessionStorage.getItem('popup');
        const secondaryLastClosedTime = sessionStorage.getItem('secondaryPopup');
        const currentTime = new Date().getTime();
        const displayUntilDate = new Date('2024-02-29').getTime();

        const timeout = setTimeout(() => {
            if (
                (!indexLastClosedTime || currentTime - indexLastClosedTime > 24 * 60 * 60 * 1000)
                &&
                (!secondaryLastClosedTime || currentTime - secondaryLastClosedTime > 24 * 60 * 60 * 1000)
                &&
                currentTime < displayUntilDate
            ) {
                setShowPopup(true);
            }
        }, 3000);

        return () => clearTimeout(timeout);

    }, []);

    const handleClose = () => {
        setShowPopup(false);
        sessionStorage.setItem('popup', new Date().getTime());
    }


    return (
        <>
            {/* SEO section */}
            <Seo title={SEOTitle} description={SEODescription}></Seo>
            <Nav homepageNav={true} />

            {showPopup && (
                <div className="popup-container" uk-scrollspy="cls: uk-animation-scale-up; target: .popup;">
                    <div className="popup">
                        <div className="popup-content">
                            <p>🍷 <b>29 February save the date! 🍷<br />
                                Cantine “Bolgheri” wine testing experience</b></p>

                            <p> Bolgheri's wines are generally a base blend of Cabernet (Sauvignon or Franc) and
                                Merlot, often with additions of Syrah, Petit Verdot and, on rare occasions, Sangiovese.
                                Over recent years, the number of wineries that produce monovarietal wines using one
                                of the three main grape varieties has continued to increase and we would like to give
                                the opportunity to our customer to discover those treasure that Italy territory provide.
                                Discover <a href="/wine-tasting"><b><i>our menu</i></b></a>.</p>

                            <p className="no-margin">💌 To make the evening even more memorable, we recommend securing your table in advance.</p>

                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }} className="widget">
                                <iframe src="https://www.opentable.co.uk/widget/reservation/canvas?rid=317766&amp;type=button&amp;theme=standard&amp;overlay=false&amp;domain=couk&amp;lang=en&amp;r3uid=MiUIESaB3&amp;newtab=false&amp;disablega=true&amp;color=1" width="210" height="113" frameborder="0" scrolling="no" name="opentable-make-reservation-widget" title="Online Bookings | OpenTable, La Tagliata - New"></iframe>
                            </div>

                            <p className="no-margin" style={{ paddingBottom: "20px" }}> An unforgettable sensory experience awaits. We look forward to hosting you!<br /></p>
                            <button onClick={handleClose}>Close</button>
                        </div>
                    </div>
                </div>
            )}

            <main>{children}</main>

            {/* footer section */}
            <Footer />
        </>
    )

}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
